import React, { useState, useEffect, useRef } from 'react'
import "./WeeklyTimesheet.css"

import DatePicker from "react-datepicker";
import { KeyboardArrowDown } from '@mui/icons-material'
import moment from 'moment'
import { getWeek } from 'date-fns'
import CryptoJS from 'crypto-js';

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import "react-datepicker/dist/react-datepicker.css";
import { getUserData } from '../../../redux/features/UserSlice';
import { getInitialValues } from '../../../redux/features/InitialSlice';

import { toast } from 'react-toastify'
import { handleGetWeeklyTimesheetDetails } from '../../Pages/WeeklyTimesheetHandler';

import { setLoadingScreen } from '../../../redux/features/ActivateSlice';
import Messages from '../../../data/Messages.json'
import { AxiosPost, getCurrentDate, getWeekFromWeekUtils } from '../../../data/utilities';

const weeks = getWeekFromWeekUtils();

function WeeklyTimesheet() {

    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const initialData = useSelector(getInitialValues)
    const [paramsValue, setParamsValue] = useState(null)

    const [EmployeeSheet, setEmployeeSheet] = useState([])
    const [TempEmployeeSheet, setTempEmployeeSheet] = useState([])

    const [Year, setYear] = useState(null)
    const [currentDate, setCurrentDate] = useState(null)
    const [Month, setMonth] = useState('')

    const [currentWeek, setCurrentWeek] = useState(null)

    const [numberOfWeekInYear, setNumberOfWeekInYear] = useState([])

    const activeWeek = useRef(null);
    const [scroll, setScroll] = useState(false)

    const navigate = useNavigate()

    const userData = useSelector(getUserData)

    const [FKEmpId, setFKEmpId] = useState(null)

    useEffect(() => {

        let FKId = null

        if (params.EmpId !== undefined) {
            let value = JSON.parse(CryptoJS.AES.decrypt(params.EmpId.split('|').join("/"), process.env.REACT_APP_SECRECT_KEY).toString(CryptoJS.enc.Utf8))
            FKId = Number.parseInt(value.Id)
            setParamsValue(value)
            setFKEmpId(Number.parseInt(value.Id))
        }
        else {
            FKId = userData.Id
            setFKEmpId(userData.Id)
        }

        let numberOfWeeks = 0;
        const dt = getCurrentDate()

        if (Year === null) {
            setYear(dt.getFullYear())

            // Current Week of the year
            setCurrentDate(new Date(`${dt.getFullYear()}-0${dt.getMonth() + 1}-${dt.getDate()}`))

            numberOfWeeks = weeks.localWeeks(dt.getFullYear())

            setNumberOfWeekInYear(numberOfWeeks)
        }

        if (FKId !== null) {

            if (params.EmpId !== undefined) {
                dispatch(setLoadingScreen(true))
                AxiosPost(`/timesheet/get-weekly-timesheet-by-fk-id`, {
                    FKId: FKId,
                    Year: dt.getFullYear(),
                    NumberOfWeeks: numberOfWeeks
                }).then((res) => {
                    if (res.data.isSuccess) {
                        dispatch(setLoadingScreen(false))
                        setEmployeeSheet(res.data.EmployeeTimesheet)
                        setTempEmployeeSheet(res.data.EmployeeTimesheet)
                    }
                })
            }
            else {
                if (initialData.length !== 0) {
                    setEmployeeSheet(initialData.WeeklyTimesheet)
                    setTempEmployeeSheet(initialData.WeeklyTimesheet)
                }
            }
            setScroll(true)
            setCurrentWeek(getWeek(getCurrentDate()))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, userData.Id, initialData])

    useEffect(() => {

        if (activeWeek.current !== null) {
            if (scroll) {
                const dt = getCurrentDate()

                if (dt.getFullYear() === Year) {
                    setCurrentWeek(getWeek(getCurrentDate()))

                    const offset = -400; // Offset value in pixels
                    const options = {
                        behavior: 'smooth',
                        offset: offset, // Negative value to scroll above the target
                    };

                    activeWeek.current.scrollIntoView(options);

                }
            }
        }

    }, [Year, scroll])

    const handleYearChange = (date) => {

        if (Number.parseInt(date.toString().split(' ')[3]) > getCurrentDate().getFullYear() + 1) {
            toast.error(Messages.Weekly_Timesheet.Weekly_Timesheet_Year_Notfound, { toastId: "iemployee-toast" })
            return false
        }

        setCurrentDate(date)
        setYear(Number.parseInt(date.toString().split(' ')[3]))

        let numberofweeks = weeks.localWeeks(Number.parseInt(date.toString().split(' ')[3]))
        setNumberOfWeekInYear(numberofweeks)

        handleGetWeeklyTimesheetDetails(
            dispatch,
            FKEmpId,
            numberofweeks,
            setScroll,
            setEmployeeSheet,
            setTempEmployeeSheet,
            Number.parseInt(date.toString().split(' ')[3]),
            setCurrentWeek
        )

    }

    const handleStartDate = (weekNum, type) => {

        const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        let result = moment().year(Year).week(weekNum).startOf('week')
        let month = 0

        if (type === 'month') {
            Months.every((e, index) => {
                if (e === result._d.toString().split(' ')[1]) {
                    month = index
                    return false
                }
                else {
                    return true
                }
            })
            return month + 1
        }
        else {
            return result._d.toString().split(' ')[2]
        }

    }

    const handleEndDate = (weekNum, type) => {

        const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        let result = moment().year(Year).week(weekNum).endOf('week')
        let month = 0

        if (type === 'month') {
            Months.every((e, index) => {
                if (e === result._d.toString().split(' ')[1]) {
                    month = index
                    return false
                }
                else {
                    return true
                }
            })
            return month + 1
        }
        else {
            return result._d.toString().split(' ')[2]
        }


    }

    const handleTimesheet = (Id, year, week,  month, Status, Submitteddate, approver, updatedDt,Comment) => {

        const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        let Month = 0

        let collapse = month

        Months.forEach((item, index) => {
            month = month.split('/')[0]
            if (item.includes(month)) {
                Month = index + 1
                return false
            }
        })

        // const currentDt = getCurrentDate()

        // if (Year === currentDt.getFullYear()) {

        //     if (currentWeek < week-4) {
        //         toast.error(Messages.Weekly_Timesheet.Weekly_Timesheet_Unavailable, { toastId: "iemployee-toast" })
        //         return false
        //     }
        // }

        const SECRET_STRING = CryptoJS.AES.encrypt(JSON.stringify({
            weekId: Id,
            year: year,
            weeknumber: week,
            Comment:Comment,
            Status: Status,
            month: Month,
            type: params.EmpId !== undefined ? "Manager" : "normal",
            WeekStartDate: `${handleStartDate(week, 'month')}/${handleStartDate(week, 'startDate')}`,
            WeekEndDate: `${handleEndDate(week, 'month')}/${handleEndDate(week, 'EndDate')}`,
            goback: location.pathname,
            TotalNumberOfWeeks: numberOfWeekInYear,
            activeWeekNumber: currentWeek,
            SubmittedDate: Submitteddate,
            Approver: approver,
            UpdatedDate: updatedDt,
            FKEmpId: FKEmpId,
            collapse: collapse.split('/').length > 1 ? "collapse" : undefined
        }), process.env.REACT_APP_SECRECT_KEY).toString().split('/').join("|")
        if (paramsValue === null) {
            navigate(`/mytime/home/timesheet/${SECRET_STRING}`)
        }
        else if (paramsValue.type === 'reports') {
            navigate(`/mytime/reports/employees/timesheet/${SECRET_STRING}`)
        }
        else {
            navigate(`/mytime/my-team/team/timesheet/${SECRET_STRING}`)
        }

    }

    const handleDate = (date) => {
        const localZoneDate = new Date(date)
        return `${localZoneDate.toLocaleString().split(',')[0]} ${localZoneDate.toLocaleString().split(',')[1]}`
    }

    const handleMonth = (e) => {
        setMonth(e.target.value)
        if (e.target.value === '') {
            setEmployeeSheet(TempEmployeeSheet)
            return false
        }
        setEmployeeSheet(TempEmployeeSheet.filter(item => item.Month.includes(e.target.value)))
    }

    return (
        <div className='weekly_timesheet'>
            {/* Header */}
            <div className='weekly_timesheet_container'>
                <div className='weekly_timesheet_container_title'>
                    <span>Weekly Time Sheet</span>
                </div>
                <div className='weekly_timesheet_container_navigations'>
                    <div className='weekly_timesheet_container_navigate_date'>
                        <label htmlFor='datepicker'>
                            <b>Year :</b>
                            <div className='weekly_timesheet_container_navigations_dt'>
                                <span> {Year}</span>
                                <KeyboardArrowDown fontSize='small' />
                            </div>
                        </label>
                        <DatePicker
                            id='datepicker'
                            value={currentDate}
                            selected={currentDate}
                            onChange={handleYearChange}
                            showYearPicker
                            yearItemNumber={10}
                            minDate={new Date("1950-01-01")}
                        />
                    </div>
                    <div className='weekly_timesheet_month_container'>
                        <span>Month: </span>
                        <select
                            value={Month}
                            onChange={handleMonth}
                        >
                            <option value={''}>All</option>
                            <option value={'Jan'}>January</option>
                            <option value={'Feb'}>February</option>
                            <option value={'Mar'}>March</option>
                            <option value={'Apr'}>April</option>
                            <option value={'May'}>May</option>
                            <option value={'Jun'}>June</option>
                            <option value={'Jul'}>July</option>
                            <option value={'Aug'}>August</option>
                            <option value={'Sep'}>September</option>
                            <option value={'Oct'}>October</option>
                            <option value={'Nov'}>November</option>
                            <option value={'Dec'}>December</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='weekly_timesheet_content'>
                {/* Table */}
                {
                    EmployeeSheet.length !== 0 ?
                        <table>
                            <thead>
                                <tr>
                                    {
                                        Columns.map((col, index) => {
                                            return (
                                                <td key={index} style={{ width: col.width }} >{col.name}</td>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    EmployeeSheet.map((week, index) => {
                                        return (
                                            <>
                                                {
                                                    week.WeekNumber === (currentWeek - 4) ?
                                                        <tr key={index}
                                                            ref={activeWeek}
                                                            onClick={() =>
                                                                handleTimesheet(week.WeekId, Year, week.WeekNumber, week.Month,  week.Status, week.SubmittedDate, week.Approver, week.UpdatedBy, week.Comment)
                                                            }
                                                        >
                                                            <td>{week.WeekNumber}</td>
                                                            <td style={{ color: "#6350FF" }}>{handleStartDate(week.WeekNumber, 'month')}/{handleStartDate(week.WeekNumber, 'startDate')} - {handleEndDate(week.WeekNumber, 'month')}/{handleEndDate(week.WeekNumber, 'EndDate')}</td>
                                                            <td>{week.Month}</td>
                                                            <td>
                                                                <span className={
                                                                    week.Status === 'Pending' ?
                                                                        'weekly_status_pending' :
                                                                        week.Status === 'Rejected' ?
                                                                            "weekly_status_rejected" :
                                                                            week.Status === 'Approved' ?
                                                                                "weekly_status_approved" :
                                                                                week.Status ?
                                                                                    "weekly_status_inapproval" :
                                                                                    "weekly_status_na"
                                                                }
                                                                >
                                                                    {
                                                                        week.Status === null ? "Not Started" : week.Status
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    week.Total
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    week.SubmittedDate === null ?
                                                                        "Not Started"
                                                                        :
                                                                        handleDate(week.SubmittedDate)
                                                                }
                                                            </td>
                                                            <td style={{ textTransform: "capitalize" }}>
                                                                {
                                                                    week.Approver !== null ?
                                                                        week.Approver :
                                                                        "Not Started"
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        <tr key={index}
                                                            className={week.WeekNumber === currentWeek ? 'weekly_timesheet_content_active' : ""}
                                                            onClick={() => handleTimesheet(week.WeekId, Year, week.WeekNumber, week.Month, week.Status, week.SubmittedDate, week.Approver, week.UpdatedBy, week.Comment)}
                                                        >
                                                            <td>{week.WeekNumber}</td>
                                                            <td style={{ color: "#6350FF" }}>{handleStartDate(week.WeekNumber, 'month')}/{handleStartDate(week.WeekNumber, 'startDate')} - {handleEndDate(week.WeekNumber, 'month')}/{handleEndDate(week.WeekNumber, 'EndDate')}</td>
                                                            <td>{week.Month}</td>
                                                            <td>
                                                                <span className={
                                                                    week.Status === 'Pending' ?
                                                                        'weekly_status_pending' :
                                                                        week.Status === 'Rejected' ?
                                                                            "weekly_status_rejected" :
                                                                            week.Status === 'Approved' ?
                                                                                "weekly_status_approved" :
                                                                                week.Status ?
                                                                                    "weekly_status_inapproval" :
                                                                                    "weekly_status_na"
                                                                }
                                                                >
                                                                    {
                                                                        week.Status === null ? "Not Started" : week.Status
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {
                                                                    week.Total
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    week.SubmittedDate === null ?
                                                                        "Not Started"
                                                                        :
                                                                        handleDate(week.SubmittedDate)
                                                                }
                                                            </td>
                                                            <td style={{ textTransform: "capitalize" }}>
                                                                {
                                                                    week.Approver !== null ?
                                                                        week.Approver :
                                                                        "Not Started"
                                                                }
                                                            </td>
                                                        </tr >
                                                }
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <></>
                }

            </div>
        </div>
    )
}

export default WeeklyTimesheet


const Columns = [
    {
        id: 0,
        name: "Week #",
        width: "8%"
    },
    {
        id: 1,
        name: "Week Entry",
        width: "10%"
    },
    {
        id: 2,
        name: "Month",
        width: "10%"
    },
    {
        id: 3,
        name: "Status",
        width: "10%"
    },
    {
        id: 4,
        name: "Total",
        width: "8%"
    },
    {
        id: 5,
        name: "Submitted Date",
        width: "18%"
    },
    {
        id: 6,
        name: "Approver",
        width: "20%"
    },
    // {
    //     id: 7,
    //     name: "Appr/Rej Date",
    //     width: "18%"
    // }
]