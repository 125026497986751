import { React, useEffect, useState } from "react";
import "./ProjectCodeGenerator.css";

import {
  getInitialValues,
  setInitialValues,
} from "../../../redux/features/InitialSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setLoadingScreen } from "../../../redux/features/ActivateSlice";
import { getUserData } from "../../../redux/features/UserSlice";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import { AxiosPost, getCurrentDate } from "../../../data/utilities";
import Messages from "../../../data/Messages.json";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function GenerateProjectCode() {
  const initialData = useSelector(getInitialValues);
  const userData = useSelector(getUserData);
  const dispatch = useDispatch();

  const [selectedClient, setSelectedClient] = useState("Select Client Name");
  const [shortCode, setShortCode] = useState("");

  const [projectName, setProjectName] = useState("");

  const [projectCodes, setProjectCodes] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);

  const [practice, setPractice] = useState("Select Practice");
  const [market, setMarket] = useState("Select Market");
  const [clientType, setClientType] = useState("Select Client Type");
  const [partner, setPartner] = useState("Select Partner");
  const [partners, setPartners] = useState([]);
  const [subMarket, setSubMarket] = useState("Select Submarket");
  const [incomeclass, setIncomeClass] = useState("Select Income Class");
  const [practices, setPractices] = useState([]);
  const [subMarkets, setSubMarkets] = useState([]);
  //const [Markets, setMarkets] = useState([]);
  //const [incomeClasses, setIncomeClasses] = useState([]);

  const [projectCode, setProjectCode] = useState("");

  const [isError, setIsError] = useState({
    isClientType: false,
    isPartner: false,
    isClient: false,
    isClientCode: false,
    isProject: false,
    isMarket: false,
    isSubmarket: false,
    isPracitce: false,
    isIncomeClass: false,
  });

  useEffect(() => {
    if (initialData.length !== 0) {
      setProjectCodes(initialData.ProjectCodes);
    }
  }, [initialData]);

  // useEffect(() => {
  //   AxiosGet(`/timesheet/get-markets`).then((result) => {
  //     if (result.data.isSuccess) {
  //       setMarkets(result.data.Markets);
  //     }
  //   });
  //   AxiosGet(`/timesheet/get-income-classes`).then((result) => {
  //     if (result.data.isSuccess) {
  //       setIncomeClasses(result.data.InComeClasses);
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (clientType === "P") {
      setPartners(partnershipPartners);
    } else if (clientType === "D") {
      setPartners(directPartners);
    } else {
      setPartners([]);
    }
    setPartner(""); // Reset partner when clientType changes
  }, [clientType]);

  useEffect(() => {
    if (market === "1") {
      setSubMarkets(subMarketValuesHealthcare);
      setPractices(healthcarePractices);
    } else if (market === "2") {
      setSubMarkets(subMarketValuesRetail);
      setPractices(retailPractices);
    } else {
      setSubMarkets([]);
      setPractices([]);
    }
  }, [market]);

  useEffect(() => {
    // let filterMarket = "";
    // if (market !== "") {
    //   Markets.forEach((item, index) => {
    //     if (item.Market === market) {
    //       filterMarket = index + 1;
    //     }
    //   });
    // }

    // let filterpractice = "";
    // if (practice !== "") {
    //   initialData.PracticeAreas.forEach((item, index) => {
    //     if (item.PracticeArea === practice) {
    //       filterpractice = index + 1;
    //     }
    //   });
    // }

    // let filterIncomeclass = "";
    // if (incomeclass !== "") {
    //   IncomeClasses.forEach((item, index) => {
    //     if (item.Incomeclass === incomeclass) {
    //       filterIncomeclass = index + 1;
    //     }
    //   });
    // }

    if (
      clientType !== "" &&
      partner !== "" &&
      shortCode !== "" &&
      market !== 0 &&
      subMarket !== 0 &&
      practice !== 0 &&
      incomeclass !== 0
    ) {
      let projectCreation =
        clientType +
        partner +
        shortCode.toString().toUpperCase() +
        market +
        subMarket +
        practice +
        incomeclass;

      const projectNumber =
        projectCodes
          .filter((item) => item.Typename === "PROJECT")
          .filter((item) => item.Client === selectedClient).length + 1;
      // Append the project number to the project code
      const finalProjectCode = `${projectCreation}P${projectNumber}`;

      setProjectCode(finalProjectCode);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientType,
    partner,
    market,
    subMarket,
    practice,
    projectCodes,
    incomeclass,
    selectedClient,
    shortCode,
    setProjectCode,
  ]);

  const handleGenerateProjectCode = async () => {
    if (
      clientType === "Select Client Type" ||
      partner === "Select Partner" ||
      selectedClient === "Select Client Name" ||
      shortCode === "" ||
      market === "Select Market" ||
      subMarket === "Select Submarket" ||
      practice === "Select Practice" ||
      incomeclass === "Select Income Class" ||
      projectName === ""
    ) {
      toast.error(Messages.Assign_ProjectCodes.AllocationProvideFields, {
        toastId: "iEmployee-id",
      });

      let values = isError;
      if (selectedClient === "Select Client Type") {
        values = { ...values, isClientType: true };
      }
      if (selectedClient === "Select Partner") {
        values = { ...values, isPartner: true };
      }
      if (selectedClient === "Select Client Name") {
        values = { ...values, isClient: true };
      }
      if (shortCode === "") {
        values = { ...values, isClientCode: true };
      }
      if (projectName === "") {
        values = { ...values, isProject: true };
      }
      if (market === "Select Market") {
        values = { ...values, isMarket: true };
      }
      if (subMarket === "Select Submarket") {
        values = { ...values, isSubmarket: true };
      }
      if (practice === "Select Practice") {
        values = { ...values, isPracitce: true };
      }
      if (incomeclass === "Select Income Class") {
        values = { ...values, isIncomeClass: true };
      }

      setIsError(values);
    } else {
      dispatch(setLoadingScreen(true));
      let result = await AxiosPost(`/timesheet/generate-project-code`, {
        ClientName: selectedClient,
        ClientCode: shortCode.toString().toUpperCase(),
        ProjectName: projectName,
        ProjectCode: projectCode,
        ExpiryDate: expiryDate === null ? "9999-12-31" : expiryDate,
        FKEmpId: userData.Id,
      });

      dispatch(setLoadingScreen(false));

      if (result.data.isSuccess) {
        handleClear();
        dispatch(
          setInitialValues({
            ...initialData,
            ProjectCodes: result.data.AllocatedProjectCodes,
          })
        );
        toast.success(result.data.Message, { toastId: "iEmployee-id" });
      } else {
        toast.error(result.data.Message, { toastId: "iEmployee-id" });
      }
    }
  };

  const handleValiadteClient = Array.from(
    new Set(
      initialData.ProjectCodes.filter((item) => item.Client).map(
        (option) => option.Client
      )
    )
  ).map((client) => ({ Client: client }));

  const handleClear = () => {
    setSelectedClient("Select Client Name");
    setShortCode("");
    setProjectName("");
    setProjectCode("");

    setClientType("Select Client Type");
    setPartner("Select Partner");
    setMarket("Select Market");
    setSubMarket("Select Submarket");
    setPractice("Select Practice");
    setIncomeClass("Select Income Class");
  };

  return (
    <div className="generate_project_code_container">
      <div className="generate_project_code_container_header">
        <div className="generate_project_code_container_header_title">
          <span>Project Code Generation</span>
        </div>
      </div>
      {/* generate_project_code */}
      <div className="generate_project_code_flex_div">
        <div className="generate_project_code">
          <div className="generate_project_code_header">
            {/* client Name  & Client code ##### Step 1*/}
            <div className="generate_project_code_input_box">
              <div className="generate_project_code_container_span">
                <FormControl fullWidth>
                  <InputLabel id="client-type-label">*Client Type</InputLabel>
                  <Select
                    required
                    labelId="client-type-label"
                    id="client-type-select"
                    value={clientType}
                    onChange={(e) => setClientType(e.target.value)}
                    label="Client Type"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="Select Client Type">
                      Select Client Type
                    </MenuItem>
                    {clientTypes.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="generate_project_code_container_span">
                <FormControl fullWidth>
                  <InputLabel id="partner-label">*Partner</InputLabel>
                  <Select
                    required
                    labelId="partner-label"
                    id="partner-select"
                    value={partner}
                    onChange={(e) => {
                      if (clientType !== "Select Client Type") {
                        setPartner(e.target.value);
                        setIsError({ ...isError, isPartner: false });
                      } else {
                        toast.warning(
                          "Select Client Type first before selecting Partner",
                          { toastId: "iemployee-toast" }
                        );
                      }
                    }}
                    label="Partner"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="Select Partner">Select Partner</MenuItem>
                    {partners.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="generate_project_code_input_box">
              <div className="generate_project_code_container_span">
                <Autocomplete
                  required
                  onChange={(event, newValue) => {
                    setSelectedClient(
                      newValue === null ? "Select Client Name" : newValue.Client
                    );
                    const selectedOption = initialData.ProjectCodes.find(
                      (option) =>
                        option.Client ===
                        (newValue === null ? "" : newValue.Client)
                    );
                    setShortCode(
                      selectedOption ? selectedOption.ShortCode : ""
                    );
                  }}
                  options={handleValiadteClient}
                  getOptionLabel={(option) => {
                    if (option.Client === undefined) {
                      return option;
                    } else {
                      return option.Client;
                    }
                  }}
                  sx={{ width: "100%" }}
                  value={selectedClient}
                  filterOptions={(options, { inputValue }) => {
                    if (!inputValue) {
                      return [];
                    }
                    return options.filter((option) =>
                      option.Client.toLowerCase().includes(
                        inputValue.toLowerCase()
                      )
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Client Name"
                      onChange={(e) => setSelectedClient(e.target.value)}
                    />
                  )}
                />
              </div>
              <div className="generate_project_code_container_span">
                <TextField
                  required
                  type="text"
                  label="Client Short Code"
                  sx={{ width: "100%" }}
                  onChange={(e) => setShortCode(e.target.value)}
                  value={shortCode}
                  placeholder="Enter Client Code"
                />
              </div>
            </div>

            {/* Markets & Practices */}
            <div className="generate_project_code_input_box">
              <div className="generate_project_code_container_span">
                <TextField
                  required
                  type="text"
                  placeholder="Enter Project Name"
                  label="Project Name"
                  sx={{ width: "100%" }}
                  value={projectName}
                  error={isError.isProject}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      setProjectName(e.target.value);
                      setIsError({ ...isError, isProject: false });
                    }
                  }}
                />
              </div>
              <div className="generate_project_code_container_span">
                <FormControl fullWidth>
                  <InputLabel id="market-select-label">Markets*</InputLabel>
                  <Select
                    required
                    labelId="market-select-label"
                    id="market-select"
                    value={market}
                    error={isError.isMarket}
                    onChange={(e) => {
                      if (selectedClient !== "Select Client Name") {
                        setMarket(e.target.value);
                        setIsError({ ...isError, isMarket: false });
                      } else {
                        toast.warning(
                          "Select client first before selecting market",
                          { toastId: "iemployee-toast" }
                        );
                      }
                    }}
                    label="Markets"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Select Market"}>Select Market</MenuItem>
                    {marketValues.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="generate_project_code_input_box">
              <div className="generate_project_code_container_span">
                <FormControl fullWidth>
                  <InputLabel id="submarket-select-label">
                    Submarket*
                  </InputLabel>
                  <Select
                    required
                    labelId="submarket-select-label"
                    id="submarket-select"
                    value={subMarket}
                    error={isError.isSubmarket}
                    onChange={(e) => {
                      if (market !== "Select Market") {
                        setSubMarket(e.target.value);
                        setIsError({ ...isError, isSubmarket: false });
                      } else {
                        toast.warning(
                          "Select market first before selecting submarket",
                          { toastId: "iemployee-toast" }
                        );
                      }
                    }}
                    label="Submarket"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Select Submarket"}>
                      Select Submarket
                    </MenuItem>
                    {subMarkets.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="generate_project_code_container_span">
                <FormControl fullWidth>
                  <InputLabel id="practice-select-label">
                    Practice/ Platform*
                  </InputLabel>
                  <Select
                    required
                    labelId="practice-select-label"
                    id="practice-select"
                    value={practice}
                    error={isError.isPractice}
                    onChange={(e) => {
                      if (subMarket !== "Select Submarket") {
                        setPractice(e.target.value);
                        setIsError({ ...isError, isPractice: false });
                      } else {
                        toast.warning(
                          "Select submarket first before selecting practice",
                          { toastId: "iemployee-toast" }
                        );
                      }
                    }}
                    label="Practice"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Select Practice"}>
                      Select Practice
                    </MenuItem>
                    {practices.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="generate_project_code_input_box">
              <div className="generate_project_code_container_span">
                <FormControl fullWidth>
                  <InputLabel id="incomeclass-select-label">
                    Income Classes*
                  </InputLabel>
                  <Select
                    required
                    labelId="incomeclass-select-label"
                    id="incomeclass-select"
                    value={incomeclass}
                    error={isError.isIncomeClass}
                    onChange={(e) => {
                      if (practice !== "Select Practice") {
                        setIncomeClass(e.target.value);
                        setIsError({ ...isError, isIncomeClass: false });
                      } else {
                        toast.warning(
                          "Select practice first before selecting income class",
                          { toastId: "iemployee-toast" }
                        );
                      }
                    }}
                    label="Income Classes"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"Select Income Class"}>
                      Select Income Class
                    </MenuItem>
                    {incomeClasses.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="generate_project_code_container_span">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Expiry Date"
                    inputFormat="MM/DD/YYYY"
                    value={expiryDate}
                    onChange={(value) => {
                      const dt = new Date(value);
                      if (dt.getTime() > getCurrentDate().getTime()) {
                        setExpiryDate(value);
                      } else {
                        toast.warning("Please select the proper expiry date", {
                          toastId: "iemployee-toast",
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        name="expiry_date"
                        {...params}
                        sx={{ width: "100%", height: "35px !important" }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="generate_project_code_input_box">
              <div></div>
              <div className="generate_project_code_container_span">
                <TextField
                  required
                  type="text"
                  label="Project Code"
                  sx={{ width: "100%" }}
                  onChange={(e) => setProjectCode(e.target.value)}
                  value={projectCode}
                  placeholder="ProjectCode"
                  disabled
                />
              </div>
              <div></div>
            </div>
          </div>
          <div className="generate_project_code_container_navigations">
            <div className="generate_project_code_btn">
              <button onClick={handleGenerateProjectCode}>Generate</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateProjectCode;

const clientTypes = [
  {
    name: "Partnership",
    value: "P",
  },
  {
    name: "Direct",
    value: "D",
  },
];

const partnershipPartners = [
  {
    name: "Microsoft",
    value: "M",
  },
  {
    name: "UI Path",
    value: "U",
  },
  {
    name: "Snowflake",
    value: "S",
  },
  {
    name: "Salesforce",
    value: "SF",
  },
];

const directPartners = [
  {
    name: "Customer",
    value: "C",
  },
];

const marketValues = [
  {
    name: "Healthcare",
    value: "1",
  },
  {
    name: "Retail",
    value: "2",
  },
];

const subMarketValuesHealthcare = [
  {
    name: "C&SI (Consulting & System Integration)",
    value: "1",
  },
  {
    name: "CPS-HC",
    value: "2",
  },
  {
    name: "HIP-ONE",
    value: "3",
  },
  {
    name: "PES",
    value: "4",
  },
];

const subMarketValuesRetail = [
  {
    name: "Retail",
    value: "1",
  },
];

const healthcarePractices = [
  {
    name: "Digital Engineering",
    value: "1",
  },
  {
    name: "AI & Automation",
    value: "2",
  },
  {
    name: "Data",
    value: "3",
  },
  {
    name: "Cloud",
    value: "4",
  },
  {
    name: "Platform - CPS",
    value: "5",
  },
  {
    name: "Platform - HIP-ONE",
    value: "6",
  },
  {
    name: "Platform - PES",
    value: "7",
  },
];

const retailPractices = [
  {
    name: "Retail Soultions",
    value: "1",
  },
  {
    name: "Data",
    value: "2",
  },
  {
    name: "Cloud",
    value: "3",
  },
];

const incomeClasses = [
  {
    name: "Services Revenue",
    value: "1",
  },
  {
    name: "Subscription Revenue",
    value: "2",
  },
  {
    name: "Cloud Billable Revenue",
    value: "3",
  },
];
